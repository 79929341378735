.business-footer {
  /* height: 140px; */
  background-color: var(--blue-text);
  padding: 48px 16px;
  background-color: #205DA1;
}

.business-footer-content {
  max-width: 1128px;
  margin: auto;
}

.footer-row-1, .footer-row-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-row-2 {
  margin-top: 24px;
  color: white;
}

.footer-row-2 a {
  color: white;
  text-decoration: none;
  outline: none;
}
.footer-social {
  display: flex;
  justify-content: flex-end;
}

.footer-social-item a {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.footer-social-item:not(:first-child) {
  margin-left: 32px;
}

.footer-social-title {
  font-size: 16px;
  font-weight: 600;
  margin-left: 8px;
  color: white;
  outline: none;
  border: none;
  text-decoration: none;
}

@media screen and (max-width: 900px) {
  .business-footer {
    padding: 48px 16px;
  }

  .footer-row-1, .footer-row-2 {
    display: block;
  }

  .footer-social {
    justify-content: flex-start;
    margin-top: 48px;
  }

  .footer-row-2 a {
    margin-top: 24px;
  }
}
