.what-clients-say {
  background-color: #f2f3f5;
  padding: 96px 0;
}

.what-clients-say-title {
  text-align: center;
  font-size: 40px;
  line-height: 56px;
  font-weight: 500;
  margin-bottom: 48px;
}

@media screen and (max-width: 420px) {
  .what-clients-say-title {
    font-size: 32px;
  }
}
