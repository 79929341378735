.we-are-global {
  width: 1100px;
  margin: 48px auto;
  position: relative;
  box-sizing: border-box;
}

.we-are-global-title {
  color: #4986cc;
  font-size: 40px;
  font-weight: 600;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
}

.we-are-global-image-desktop,
.we-are-global-image-mobile {
  width: 100%;
  object-fit: cover;
}

.we-are-global-image-mobile {
  display: none;
}

.get-in-touch-button {
  padding: 15px 27px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #2975cc;
  color: white;
  font-size: 16px;
  line-height: 155%;
  border: none;
  text-decoration: none;
  position: absolute;
  right: 0;
  bottom: 27px;
}

@media screen and (max-width: 1100px) {
  .we-are-global {
    width: 100%;
    padding: 0 16px;
    margin: 8px 0 16px 0;
  }

  .we-are-global-content {
    border: 1px solid #c4c8cc;
    padding-top: 40px;
    border-radius: 16px;
  }

  .we-are-global-image-desktop {
    display: none;
  }

  .we-are-global-image-mobile {
    display: block;
  }

  .we-are-global-title {
    position: relative;
    display: inline-block;
    margin: auto;
    text-align: center;
    border-bottom: 1px solid #e1e3e6;
    padding-bottom: 16px;
    margin-bottom: 4px;
  }

  .get-in-touch-button {
    display: none;
  }
}
