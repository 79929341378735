@font-face {
  font-family: "Gilmer";
  src: url("./assets/fonts/gilmer-regular.woff2") format("woff2"),
    url("./assets/fonts/gilmer-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Gilmer";
  src: url("./assets/fonts/gilmer-medium.woff2") format("woff2"),
    url("./assets/fonts/gilmer-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gilmer";
  src: url("./assets/fonts/gilmer-bold.woff2") format("woff2"),
    url("./assets/fonts/gilmer-bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

body,
input,
textarea,
button {
  /* font */
  font-family: "Gilmer", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
}

body {
  width: 100%;

  /* colors */
  --white: #ffffff;
  --white-hover: #eeeeee;
  --gray-text-title: #0a0a0a;
  --gray-text-subtitle: #2c2d2e;
  --gray-text-principle: #5d5f61;

  --gray-light: #f9f9f9;
  --gray-caption: #909499;
  --gray-on-blue: #e1e3e6;
  --gray-line: #ebedf0;

  --blue-text: #4986cc;
  --blue-text-hover: #3a6fab;
  --green-accent: #53b743;

  background-color: white;
  color: var(--black-text);
}

.page-section {
  box-sizing: border-box;
  width: 1128px;
  margin: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  font-size: 56px;
  line-height: 64px;
  font-weight: 600;
}

h2 {
  font-size: 40px;
  line-height: 56px;
}

h3 {
  font-size: 36px;
  line-height: 48px;
}

h4 {
  font-size: 32px;
  line-height: 56px;
}

h5 {
  font-size: 24px;
  line-height: 32px;
}

h6 {
  font-size: 18px;
  line-height: 36px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  color: var(--gray-caption);
}

p {
  margin: 0;
}

.zamna-principle {
  margin-top: 62px;
}

.cookie-container {
  background-color: rgba(0,0,0,0.25);
  position: fixed;
  bottom: 0;
  width: 100%;
}

.text-reversed {
  direction: rtl;
}

@media screen and (max-width: 1099px) {
  .page-section {
    width: 100%;
    max-width: 420px;
    padding: 0 16px;
  }

  .zamna-principle {
    margin-top: 0;
  }
}
