.who-we-are-content {
  display: flex;
  background-color: var(--blue-text);
  color: var(--gray-on-blue);
  padding: 32px;
  margin-top: 75px;
  border-radius: 16px;
}

.who-we-are-question {
  min-width: 255px;
  margin-right: 48px;
}

@media screen and (max-width: 1099px) {
  .who-we-are-content {
    display: block;
    padding: 32px 16px;
    margin-top: 40px;
    color: var(--blue-text);
    background: linear-gradient(
      280.64deg,
      rgba(73, 134, 204, 0.05) 0%,
      rgba(73, 134, 204, 0.025) 100%
    );
  }

  .who-we-are-question {
    margin-right: 0;
    margin-bottom: 24px;

    font-size: 32px;
    font-weight: 500;
    line-height: 56px;
  }
}
