.footer {
  height: 140px;
  background-color: var(--blue-text);
  margin-top: 72px ;
}

.footer-section {
  height: 100%;
}

.footer-links {
  display: flex;
}

.footer-links a{
  text-decoration: none;
  color: white;
}
.footer-links a:first-child {
  margin-right: 58px;
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  color: var(--white);
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

@media screen and (max-width: 1099px) {
  .footer {
    height: auto;
    display: block;
  }

  .footer-content {
    max-width: 375px;
  }

  .page-section.footer-section {
    margin: 0;
    padding: 18px 10px
  }

  .company-name {
    margin-bottom: 18px;
  }

  .footer-links a:first-child {
    margin-bottom: 5px;
  }

  .footer-links {
    flex-direction: column;
  }

  .footer-content {
    display: block;
    flex-direction: column;
    justify-content: center ;
  }

  .footer-text-part-2 {
    margin-top: 10px;
  }
}
