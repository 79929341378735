.header-root {
  color: #0a0a0a;
  background-color: white;
  padding: 0 32px;
  height: 64px;
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 -6px 10px 5px rgba(0, 0, 0, 0.15);
}

.content {
  max-width: 1128px;
  margin: auto;
  display: flex;
  align-items: center;
  height: 64px;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  gap: 8px;
}

.navigation-mobile {
  display: none;
}

.demo-page-button-header {
  padding: 9px;
  border: 1px solid #e1e3e6;
  border-radius: 8px;
  text-decoration: none;
  color: #000;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.demo-page-button-header img {
  margin-right: 8px;
}

.content .zamna-logo {
  height: 24px;
  margin: 0;
}

.burger-button {
  background: none;
  border: none;
  display: none;
  cursor: pointer;
}

.separator {
  display: none;
  margin-top: 16px;
  border-bottom: 1px solid #f2f3f5;
}

@media screen and (max-width: 900px) {
  .header-root {
    padding: 0 16px;
  }

  .header-root.fullscreen {
    position: fixed;
    height: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
  }

  .content {
    display: block;
  }

  .burger-button {
    display: block;
    position: absolute;
    right: 16px;
    top: 20px;
  }

  .content .zamna-logo {
    margin-top: 20px;
  }

  .navigation {
    display: none;
    margin: 16px 0 0 0;
  }

  .picker-pack {
    display: block;
  }

  .separator {
    display: block;
  }

  .navigation .business-website-picker,
  .navigation .language-picker {
    display: block;
    margin: 16px 0 0 0;
  }

  .navigation .business-website-picker .business-picker-button,
  .navigation .language-picker .picker-button {
    margin: 0;
  }

  .navigation.visible {
    display: block;
  }
}
