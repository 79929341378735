.zamna-intro {
  padding: 148px 32px 0 32px;
}

.zamna-intro-desktop {
  display: flex;
}

.zamna-globe {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.intro-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.zamna-logo {
  height: 24px;
  margin: 32px 0;
}

.easy-verify-title {
  max-width: 550px;
  margin-bottom: 32px;
}

.no-apps-title {
  background: -webkit-linear-gradient(right, #4986cc, #eb678f);
  white-space: pre-line;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.globe-pic {
  margin-top: -40px;
  margin-left: 50px;
}

@media screen and (max-width: 500px) {
  .no-apps-title {
    max-width: 310px;
  }

  .zamna-intro {
    padding: 0;
  }
}

@media screen and (max-width: 1099px) {
  .zamna-intro {
    padding-top: 100px;
  }
  .zamna-intro-desktop {
    display: none;
  }

  .zamna-intro-mobile {
    display: auto;
  }

  .easy-verify-title {
    max-width: 300px;
  }

  .globe-pic {
    width: 125px;
    margin: 0 12px 0 0;
  }
}

@media screen and (min-width: 1100px) {
  .zamna-intro-desktop {
    display: auto;
  }

  .zamna-intro-mobile {
    display: none;
  }
}
