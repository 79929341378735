.as-seen-in {
  text-align: center;
  background-color: #F9F9F9;
  padding: 48px 0 96px 0;
}

.as-seen-in-title {
  color: #454647;
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
  margin-bottom: 48px;
}
