.links-section {
  text-align: center;
  margin: 200px auto 128px auto;
}

.links-section-content {
  background-color: var(--gray-light);
  padding: 72px 0 20px 0;
  margin: auto;
}

.participating {
  display: flex;
  justify-content: center;
  margin-bottom: 96px;
}

.participating a:not(:first-child) {
  margin: 0 0 0 48px;
}

.partner-logo {
  opacity: 0.8;
  height: 40px;
}

.links-title {
  margin-bottom: 48px;
}

.mentioned-in {
  margin-bottom: 60px;
}

.carousel-item {
  padding: 0 30px;
  box-sizing: border-box;
}

.carousel-link-container {
  text-align: center;
}

@media screen and (max-width: 1099px) {
  .links-section {
    margin: 64px 0;
    width: 100%;
  }

  .participating {
    flex-direction: column;
  }

  .participating a:not(:first-child) {
    margin: 48px 0 0 0;
  }
}
@media screen and (max-width: 420px) {
  .links-section-content {
    background-color: var(--gray-light);
    border-radius: 0;
  }
}
