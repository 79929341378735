.demo-page-button {
  display: inline-flex;
  align-items: center;
  position: fixed;
  top: 7.69rem;
  right: 0;
  cursor: pointer;
  color: #ffffff;
  border: 0;
  grid-gap: 0.5rem;
  font-weight: 700;
  outline: none;
  padding: 1.5rem 1.2rem 1.5rem 1.7rem;
  border-radius: 100px 0 0 100px;
  background: linear-gradient(to right, #eb678f, #4986cc);
  z-index: 100;
  transition: bottom 600ms;
  font-size: 1.7rem;
}

.demo-page-button::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: #eb678f;
  animation-name: blink;
  animation-duration: 1.3s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.demo-page-button-arrow-icon {
  height: 2rem;
}

a {
  text-decoration: none;
}

@keyframes blink {
  0% {
    transform: scale3d(1, 1, 1);
    opacity: 0.8;
  }
  100% {
    transform: scale3d(1.1, 1.3, 1.1);
    opacity: 0;
  }
}

@media (max-width: 992px) {
  .demo-page-button {
    padding: 0.75rem;
    top: 20rem;
    font-size: 1rem;
  }

  .demo-page-button-arrow-icon {
    height: 1rem;
  }
}
