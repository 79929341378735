.cookies-banner {
  padding: 16px;
  border-radius: 16px;
  font-size: 15px;
  display: flex;
  align-items: center;
  max-width: 830px;
  margin: 30px auto;
  background-color: white;
  box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.1);
}

.cookies-buttons {
  display: flex;
  margin-left: 32px;
}

.cookie-button {
  font-weight: 500;
  width: 100px;
  height: 40px;
  border-radius: 8px;
  text-decoration: none;
  box-sizing: border-box;
  border: none;
  cursor: pointer;
}

.cookie-button.accept{
  background-color: var(--blue-text);
  color: var(--white);
  margin-right: 8px;
}

.cookie-button.accept:hover{
  background-color: var(--blue-text-hover);
}

.cookie-button.deny{
  font-weight: 400;
  border: 1px solid var(--gray-on-blue);
  background-color: var(--white);
  color: var(--black);
}

.cookie-button.deny:hover {
  background-color: var(--white-hover);
}

.cookies-content {
  margin-left: 16px;
}

.cookies-content a {
  color: var(--blue-text);
  text-decoration: none;
}

@media screen and (max-width: 1099px) {
  .cookies-banner {
    padding: 9px;
    flex-direction: column;
    align-items: flex-start;
    width: 320px;
    margin: 12px auto;
  }

  .cookies-banner img {
    display: none;
  }

  .cookies-content {
    margin: 0px;
  }

  .cookies-buttons {
    margin-left: 0px;
    margin-top: 9px;
    width: 100%;
  }

  .cookie-button {
    width: auto;
    flex-basis: 50%;
  }
}
