.quotes-slider {
  max-width: 1440px;
  height: 360px;
  margin: auto;
}

.quotes-slider-content {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.quotes-slide-strip {
  display: flex;
  position: absolute;
  top: 0;
  height: 98%;
  transition: 0.5s ease-in-out;
}

.without-transition {
  transition: none;
}

.quote-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 16px;
  background-color: white;
  width: 456px;
  box-sizing: border-box;
  margin: 0 12px;
  padding: 32px 32px 20px 32px;
  transition: opacity 0.5s linear;
  opacity: 0.5;
}

.highlighted {
  opacity: 1;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
}

.quote-text {
  font-size: 20px;
  line-height: 140%;
  margin-bottom: 32px;
}

.quote-author {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
}

.quote-author-title {
  font-size: 24px;
  font-weight: 500;
}

.company-logo {
  max-width: 150px;
}

.next-slide-button,
.previous-slide-button {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 480px;

  opacity: 0;
  cursor: pointer;
}

.previous-slide-button {
  left: 0;
}

.next-slide-button {
  right: 0;
}

.slider-knobs-container {
  display: flex;
  justify-content: center;
  padding-top: 24px;
}

.slider-knobs {
  display: flex;
  height: 20px;
  /* max-width: 300px; */
  margin: auto;
}

.slider-knob-touch-zone {
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  cursor: pointer;
}
.slider-knob {
  height: 4px;
  border-radius: 4px;
  width: 32px;
  background-color: #c4c8cc;
  cursor: pointer;
}

.slider-knob:not(:first-child) {
  margin-left: 8px;
}

.slider-knob.highlighted {
  background-color: #4986cc;
}

@media screen and (max-width: 420px) {
  .next-slide-button,
  .previous-slide-button {
    display: none;
  }

  .company-logo {
    max-width: 100px;
  }
}
