.root-number-banner {
  border: 1px solid #c4c8cc;
  border-radius: 16px;
  padding: 24px 32px;
  flex-basis: 49%;
  box-sizing: border-box;
}
.first-row {
  display: flex;
  justify-content: space-between;
}
.big-number {
  color: #4986cc;
  font-weight: 600;
  font-size: 40px;
}
.banner-title,
.banner-title-mobile {
  margin-top: 16px;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}

.banner-title-mobile {
  display: none;
}

@media screen and (max-width: 1100px) {
  .root-number-banner {
    padding: 16px;
  }

  .big-number {
    font-size: 32px;
  }

  .root-number-banner {
    flex-basis: 100%;
    gap: 8px;
  }

  .banner-title {
    display: none;
  }

  .banner-title-mobile {
    display: block;
    margin-top: 8px;
  }
}
