.principle-title {
  margin-bottom: 42px;
}

.principle-container {
  background-color: var(--gray-light);
  border-radius: 32px;
  padding: 32px 16px;
  text-align: center;
}

.principle-banner {
  background: linear-gradient(95.56deg, #FFA000 -2.44%, rgba(255, 160, 0, 0.8) 100%);
  box-shadow: 0px 0px 30px rgba(255, 164, 11, 0.48);
  border-radius: 16px;
  max-width: 520px;
  height: 165px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto auto 32px auto;
}

.principle-banner-text {
  max-width: 275px ;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: var(--white);
}

.principle-banner-text span:nth-child(2) {
  font-weight: 600;
}

.icon-never-see {
  width: 81px;
  height: 81px;
}

.principle-text {
  max-width: 745px;
  color: var(--gray-text-principle);
  margin: auto
}

@media screen and (max-width: 1099px) {
  .principle-title {
    margin-bottom: 32px;
  }

  .principle-banner {
    flex-direction: column ;
    height: 255px;
    padding: 0 22px;
  }

  .principle-banner-text {
    font-size: 20px;
    line-height: 32px;
    margin: 24px 0 -10px;
  }
}
