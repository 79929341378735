.protecting-data {
  padding: 82px 0 66px;
  width: 100%;
  text-align: center ;
}

.protecting-data-header {
  background-color: var(--gray-light);
  width: 100%;
  text-align: center ;
  padding: 72px 0 0;
}

.protecting-data-title {
  font-weight: 500;
}


.protecting-data-body {
  background-color: var(--gray-light);
  padding: 0 0 48px 0;
}

.protecting-data-text {
  max-width: 745px;
  margin: auto
}

.data-flow-img-desktop {
  display: auto;
  margin: 66px 0 48px 0;
}

.data-flow-img-mobile {
  display: none;
  margin: 32px 0 0;
  width: 100%;
}

@media screen and (max-width: 1099px) {
  .protecting-data-header {
    padding: 32px 0;
    width: 100%;
    text-align: center ;
  }

  .protecting-data-body {
    background-color: var(--white);
    width: 100%;
    margin: auto;
    padding: 48px 0 0 0;
  }

  .protecting-data-title {
    font-size: 32px;
    line-height: 48px;
    font-weight: 500;
  }

  .data-flow-img-desktop {
    display: none;
  }

  .data-flow-img-mobile {
    display: block;
  }
}
