.root-big-numbers-banners {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 1100px;
  margin: 32px auto 0 auto;
}

@media screen and (max-width: 1100px) {
  .root-big-numbers-banners {
    gap: 8px;
    width: 100%;
    padding: 0 16px;
  }
}
