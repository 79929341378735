.how-banners {
  margin-top: 104px;
}

.how-banner {
  display: flex;
  align-items: center;
  margin-top: 96px;
}

.how-banner:nth-child(1) .how-banner-content,
.how-banner:nth-child(3) .how-banner-content {
  padding-right: 48px;
}

.how-banner:nth-child(2) .how-banner-content {
  padding-left: 48px;
}

.how-title {
  max-width: 420px;
  margin-bottom: 24px;
}

.how-image-mobile {
  display: none;
}

.how-image-desktop {
  display: block;
  max-width: 550px;
}

.how-text {
  margin-top: 24px;
}

.how-banner-faq-advice {
  margin-top: 24px;
}

.how-banner-faq-advice span:nth-child(2) {
  font-weight: 600;
}

.faq-button {
  display: inline-block;
  box-sizing: border-box;
  padding: 0 24px;
  min-width: 110px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin-top: 24px;
  background-color: var(--blue-text);

  color: var(--white);
  border-radius: 8px;
  text-decoration: none;
}

.faq-button:hover {
  background-color: var(--blue-text-hover);
}

@media screen and (max-width: 1099px) {
  .how-banners {
    margin-top: 96px;
  }

  .how-title {
    max-width: 100%;
    font-size: 32px;
    line-height: 48px;
  }

  .how-banner:nth-child(1) .how-banner-content,
  .how-banner:nth-child(2) .how-banner-content,
  .how-banner:nth-child(3) .how-banner-content {
    padding: 0;
    width: 100%;
  }



  .how-image-mobile {
    display: block;
    width: 100%;
  }

  .how-image-desktop {
    display: none;
  }
}
