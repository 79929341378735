.page-intro {
  box-sizing: border-box;
  padding: 128px 32px;
  background-color: white;
  height: 727px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  border-radius: 0 0 48px 48px;
}

.picker-pack {
  display: flex;
}

.page-intro-image {
  display: none;
}

.page-intro-content {
  max-width: 1128px;
  color: #0a0a0a;
  margin: auto;
}

.page-intro .zamna-logo {
  height: 24px;
  margin: 32px 0;
}

.motto {
  font-weight: 600;
  font-size: 56px;
  line-height: 64px;
  max-width: 450px;
  min-width: 420px;
  margin-top: -6px;
}

.motto-description {
  font-weight: 500;
  font-size: 28px;
  line-height: 40px;
  max-width: 450px;
  margin-top: 16px;
  white-space: pre-wrap;
}

.motto-description div {
  margin-bottom: 16px;
}

@media all and (max-width: 600px) {
  .page-intro {
    height: auto;
    padding: 110px 16px 0px 16px;
    border-radius: 0;
    background-image: none;
    background: none;
  }
  .page-intro-image {
    display: block;
    height: 313px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    background-position: bottom right;
  }

  .page-intro-content {
    max-width: 340px;
    margin: 0;
  }

  .motto {
    font-size: 36px;
    line-height: 48px;
    min-width: 0;
    max-width: 300px;
  }

  .motto-description {
    font-size: 24px;
    line-height: 40px;
    padding-bottom: 32px;
  }
}
