.got-question {
  box-sizing: border-box;
  border-radius: 32px 32px 0 0;
  background-color: #2975cc;
  height: 424px;
  padding: 128px 0;
  color: white;
  display: flex;

}

.email-container {
  display: flex;
  align-items: center;
}

.email-container img {
  margin-right:16px;
}


.got-question-content {
  width: 100%;
    font-size: 48px;
    line-height: 56px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    max-width: 1128px;
    margin: auto;
}

.got-question-title {
  width: 536px;
  padding-right: 30px;
}

.got-question-contacts a {
  text-decoration: none;
  color: white
}
.social {
  display: flex;
  justify-content: flex-end;
}

.social-item a {
  display: flex;
  align-items: center;
}

.social-item:not(:first-child) {
  margin-left: 32px;
}

.social-title {
  font-size: 16px;
  font-weight: 600;
  margin-left: 8px;
}

@media screen and (max-width: 1200px) {
  .got-question {
    padding: 48px 16px;
    height: auto;
  }

  .got-question-content {
    height: auto;
  }

  .got-question-title {
    width: auto;
    font-size: 32px;
    line-height: 56px;
    text-align: left;
  }
  .got-question-content {
    display: block;
  }

  .got-question-contacts {
    margin-top: 32px;
    font-size: 32px;
  }

  .email-container {
    justify-content: flex-end;
  }
}
