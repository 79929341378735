.privacy-notice {
  padding: 32px;
}

.privacy-notice-content {
  max-width: 1200px;
}

.cookies-policy {
  padding: 32px;
}

.cookies-policy-content {
  max-width: 1200px;
}
