.demo-page-button {
  top: 8rem;
}

.what-we-do {
  text-align: center;
  background-color: #f9f9f9;
  padding: 72px 0;
}

.what-we-do-title {
  font-size: 46px;
  line-height: 56px;
  font-weight: 500;
  margin-bottom: 32px;
  margin-top: 32px;
}

.start-saving-title {
  margin-top: 32px;
}

.forward-button {
  padding: 15px 27px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #2975cc;
  color: white;
  border: none;
  text-decoration: none;
  font-size: 26px;
  line-height: 26px;
  font-weight: 400;
}

.process-animation {
  width: 100%;
  background: none;
}

.process-animation-frame {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  /*box-sizing: border-box;
  background-color: white;
  border-radius: 16px;
  border: 4px solid white;*/
  overflow: hidden;
  /* padding: 16px; */
  margin: auto;
  width: 785px;
  height: 450px;
  position: relative;
}
.video-animation {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0px;
  width: 784px;
  height: 441px;
  border: none;
  outline: none;
}

@media all and (min-width: 1128px) {
  .process-animation-frame {
    width: 1128px;
    height: 648px;
  }

  .video-animation {
    width: 1128px;
    height: 648px;
  }
}

@media all and (max-width: 800px) {
  .what-we-do {
    padding: 32px 16px 22px 16px;
  }

  .process-animation-frame {
    width: 360px;
    height: 210px;
    position: relative;
  }
  .video-animation {
    top: 0px;
    left: -5px;
    width: 370px;
    height: 202px;
  }

  .what-we-do-title {
    font-size: 32px;
    line-height: 42px;
    font-weight: 300;
    margin-bottom: 24px;
  }

  .start-saving-title {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .forward-button {
    font-size: 18px;
    line-height: 18px;
    font-weight: 200;
  }
}
