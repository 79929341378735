.language-picker {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  user-select: none;
  margin-right: 16px;
}

.picker-button {
  height: 40px;
  margin-right: 8px;
  padding: 0 8px;
  background-color: #ffffff;
  border: 1px solid #ebedf0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.language-picker.transparent .picker-button {
  background-color: transparent;
  border: 1px solid #e1e3e6;
}

.language-picker.transparent .picker-button:hover {
  background-color: transparent;
}

.chevron-icon {
  margin: 1px 0 0 10px;
}

.picker-button:hover {
  cursor: pointer;
  background-color: #eeeeee;
}

.locale-flag-icon {
  width: 22px;
  height: 14px;
}

.globe-icon {
  width: 22px;
  height: 22px;
}

.locale-name {
  font-weight: 500;
  margin: 0 0 0 14px;
}

.language-picker .language-list {
  position: absolute;
  left: 0;
  top: 64px;
  width: 206px;
  background-color: white;
  border-radius: 12px;
  padding: 8px;
  box-shadow: rgba(0, 0, 0, 0.3) 2px 5px 25px;
  z-index: 20;
}

.language-list-item {
  display: flex;
  align-items: center;
  padding: 5px 10px;
}

.language-list-item:hover {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.language-list-item-title {
  font-weight: 400;
  margin-left: 12px;
}

.left-content {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .language-picker .language-list {
    width: 139px;
    box-sizing: border-box;
    z-index: 9000;
  }
}
