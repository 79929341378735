.awards {
  padding: 50px;
  background-color: #f2f3f5;
}

.awards-container {
  max-width: 1141px;
  margin: auto;
}

.awards-title {
  text-align: left;
  font-size: 18px;
  line-height: 36px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #4986cc;
}

.awards-content {
  background-color: #2975cc;
  padding: 50px 0;
  border-radius: 24px;
}

.awards-item {
  max-width: 224px;
}

.award-logo {
  height: 74px;
  margin: auto;
}

.award-title {
  font-size: 15px;
  color: white;
  margin-top: 10px;
  white-space: pre-wrap;
  text-align: center;
}

@media all and (max-width: 600px) {
  .awards {
    padding: 50px 0;
  }
  .awards-content {
    border-radius: 0;
    max-height: 140px;
  }

  .awards-title {
    padding-left: 50px;
  }
}
