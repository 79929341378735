.we-work {
  text-align: center;
  font-size: 18px;
  line-height: 36px;
  padding: 58px 0 66px 0;
  background-color: #f9f9f9;
}

.we-work-title {
  margin-bottom: 42px;
}

@media all and (max-width: 600px) {
  .we-work {
    padding: 42px 0 48px 0;
  }
}
