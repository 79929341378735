.faq-trigger {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  user-select: none;
  padding: 24px 0;
}

.faq-question {
  font-size: 24px;
  line-height: 36px;
  font-weight: 500;
  padding-right: 16px;
}

.faq-title {
  margin-bottom: 24px;
  text-align: center;
}

.faq-container {
  border-bottom: 1px solid var(--gray-line);
}

.faq-container.open {
  padding-bottom: 24px;
}
